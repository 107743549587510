import React, {useCallback} from "react";
import {loadFull} from "tsparticles";
import {Box} from "@mui/material";
import Particles from "react-tsparticles";
import bgData from "../resources/particlesjs-config.json";

export const Background = () => {
    const particlesInit = useCallback(async engine => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await container;
    }, []);

    return (
        <>
            <Box sx={{
                zIndex: 0,
                position: "fixed",
            }}>
                <Particles id="tsparticles" options={bgData} init={particlesInit} loaded={particlesLoaded} />
            </Box>
        </>

    )
}

export default Background;