import React from 'react';
import {Box} from "@mui/material";
import img from "../../../resources/images/my_pic_2.jpg";

const Card = ({sx, image, imageSx, hasHover = true, children}) => {
    return (
        <Box sx={{
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            border: "2px solid",
            borderBottom: "5px solid",
            borderRadius: "8px",
            borderColor: `#43577a`,
            flexDirection: "column",
            boxShadow: "0 0 10px 2px #43577a",
            animation: "pulsate 2s infinite",
            transition: 'transform 0.4s ease-in-out',
            ...sx,
            '&:hover:not(:disabled)': {
                transform: hasHover ? 'translateY(-10px)' : null,
            },
            "@keyframes pulsate": {
                "0%": {
                    boxShadow: "0 0 10px 2px #43577a"
                },
                "50%": {
                    boxShadow: "0 0 15px 3px #43577a"
                },
                "100%": {
                    boxShadow: "0 0 10px 2px #43577a"
                }
            }
        }}>
            <Box src={image || img}
                 component={'img'}
                 alt={'Airton Tavares'}
                 sx={{
                     width: "250px",
                     height: "120px",
                     objectFit: "cover",
                     borderRadius: "6px 6px 0 0",
                     ...imageSx,
                 }}/>
            <Box sx={{
                width: "250px",
                height: "200px",
                bgcolor: "rgba(255,255,255,0.95)",
                borderRadius: "0 0 6px 6px"
            }}>
                {children}
            </Box>
        </Box>
    );
};

export default Card;