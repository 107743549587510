import React, {useEffect, useState} from 'react';
import {Typography} from "@mui/material";

const NumberAnimation = ({startValue = 0, endValue = 20, duration = 1000, sx = {fontFamily: "MyFont", fontSize: "30px"}}) => {
    const [currentValue, setCurrentValue] = useState(startValue);

    useEffect(() => {
        const increment = (endValue - startValue) / duration;
        const intervalId = setInterval(() => {
            setCurrentValue((prevValue) => {
                if (prevValue >= endValue) {
                    clearInterval(intervalId);
                    return endValue;
                }
                return prevValue + increment;
            });
        }, 10);

        return () => {
            clearInterval(intervalId);
        };
    }, [startValue, endValue, duration]);

    return <Typography sx={{...sx}}>{Math.round(currentValue)} +</Typography>;
};

export default NumberAnimation;