import "./index.css";
import React, {useState} from 'react';
import {Box, Grid} from "@mui/material";
import HeroSector from "./components/HeroSector";
import Background from "./components/Background";
import SkillsSector from "./components/SkillsSector";
import ProjectsSector from "./components/ProjectsSector";
import ContactsSector from "./components/ContactsSector";
import ProjectPopUp from "./components/sub-components/ProjectsSector/ProjectPopUp";
import dropBotImg1 from "./resources/images/DropBot/1.png";
import dropBotImg2 from "./resources/images/DropBot/2.png";
import sataImg1 from "./resources/images/AzoresAirlines/1.png";
import sataImg2 from "./resources/images/AzoresAirlines/2.png";
import sataImg3 from "./resources/images/AzoresAirlines/3.png";
import HoverButton from "./components/sub-components/ProjectsSector/HoverButton";

export const App = () => {
    const [isOpenDropBot, setIsOpenDropBot] = useState(false);
    const [isOpenSata, setIsOpenSata] = useState(false);

    return (
        <>
            <Box sx={{
                color: "white",
                overflowY: "auto",
                bgcolor: "#10151D",
                fontFamily: "MyFont",
            }}>
                <Background/>
                <Box sx={{
                    zIndex: 1,
                    gap: "20px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column"
                }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <HeroSector/>
                        </Grid>
                        <Grid item xs={12}>
                            <ProjectsSector setIsOpenDropBot={setIsOpenDropBot} setIsOpenSata={setIsOpenSata}/>
                        </Grid>
                        <Grid item xs={12}>
                            <SkillsSector/>
                        </Grid>
                        <Grid item xs={12}>
                            <ContactsSector/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {<ProjectPopUp isOpen={isOpenDropBot} setIsOpen={setIsOpenDropBot} title={"DropBot"}
                           images={[dropBotImg1, dropBotImg2]} description={`The DropBot was a project I created using the Discord API and Python programming language. It was hosted on Heroku. The main objective of this bot was to provide Twitch users with real-time notifications regarding drop events from their favorite streamers. By sending timely notifications directly to their Discord servers, users could stay informed about the drops. However, with the emergence of more advanced solutions like browser extensions, the DropBot has been discontinued.`}>
                <Box sx={{
                    gap: "10px",
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <HoverButton text={"Python"}/>
                    <HoverButton text={"Heroku"}/>
                </Box>
            </ProjectPopUp>}
            {<ProjectPopUp isOpen={isOpenSata} setIsOpen={setIsOpenSata} title={"Azores Airlines"}
                           images={[sataImg1, sataImg2, sataImg3]} description={`As of 2022, I have been part of a small team of developers responsible for developing and maintaining over 100 applications. My primary focus has been on Java and PHP applications, but I also occasionally work on frontend technologies such as React and Typescript. Throughout my experience, I have been involved in more than 20 applications, where my tasks have ranged from maintenance and feature additions to the development of entirely new applications.`}>
                <Box sx={{
                    ml: "5px",
                    mr: "5px",
                    gap: "5px",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center"
                }}>
                    <HoverButton text={"Java"}/>
                    <HoverButton text={"PHP"}/>
                    <HoverButton text={"React"}/>
                    <HoverButton text={"TypeScript"}/>
                    <HoverButton text={"Drupal"}/>
                    <HoverButton text={"Docker"}/>
                    <HoverButton text={"Kubernetes"}/>
                </Box>
            </ProjectPopUp>}
        </>

    )
}

export default App;
