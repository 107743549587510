import React from 'react';
import {Box, Typography} from "@mui/material";
import sata from "../resources/images/SATA.png"
import discord from "../resources/images/discord.png"
import sitelogo from "../resources/images/mylogo.ico"
import Card from "./sub-components/ProjectsSector/Card";
import NumberAnimation from "./sub-components/NumberAnimation";
import HoverButton from "./sub-components/ProjectsSector/HoverButton";
import ProjectPopUp from "./sub-components/ProjectsSector/ProjectPopUp";

const ProjectsSector = ({setIsOpenDropBot, setIsOpenSata}) => {

    return (
        <>
            <Box id={"projects"} sx={{
                mt: "3%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
            }}>
                <Typography
                    sx={{
                        mb: {xs: "5%", sm: "2%"},
                        color: "white",
                        fontSize: {xs: "20px", sm: "30px"},
                        fontFamily: "MyFont"
                    }}
                >
                    Projects
                </Typography>
                <Box sx={{
                    mb: "2%",
                    gap: "13%",
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: "center",
                    flexDirection: {xs: "column", sm: "row"}
                }}>
                    <Box sx={{cursor: "pointer"}} onClick={() => {
                        document.body.style.overflow = "hidden";
                        setIsOpenSata(true)
                    }}>
                        <Card sx={{mb: {xs: "15%", sm: 0}}} image={sata}
                              imageSx={{objectFit: "contain", background: "white"}}>
                            <Box sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}>
                                <NumberAnimation
                                    duration={35}
                                    sx={{
                                        mt: "10px",
                                        fontSize: "50px",
                                        color: "#252c42",
                                        fontWeight: "bold",
                                        fontFamily: "MyFont"
                                    }}/>
                                <Typography sx={{
                                    fontSize: "35px",
                                    color: "#252c42",
                                    fontWeight: "bold",
                                    fontFamily: "MyFont"
                                }}>Projects</Typography>
                                <Box sx={{
                                    mt: "15px",
                                    gap: "10px",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}>
                                    <HoverButton text={"Java"}/>
                                    <HoverButton text={"PHP"}/>
                                    <HoverButton text={"React"}/>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                    <Box sx={{cursor: "pointer"}} onClick={() => {
                        document.body.style.overflow = "hidden";
                        setIsOpenDropBot(true)
                    }}>
                        <Card image={discord} imageSx={{objectFit: "fill", background: "white"}}>
                            <Box sx={{
                                gap: "5px",
                                width: "100%",
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "center",
                                flexDirection: "column",
                                justifyContent: "center"
                            }}>
                                <Typography sx={{
                                    fontSize: "28px",
                                    color: "#252c42",
                                    fontWeight: "bold",
                                    fontFamily: "MyFont"
                                }}>DropBot</Typography>
                                <Typography sx={{
                                    pr: "10px",
                                    pl: "10px",
                                    fontSize: "14px",
                                    color: "#252c42",
                                    fontWeight: "bold",
                                    textAlign: "justify",
                                    fontFamily: "MyFont"
                                }}>
                                    A Discord bot designed to assist users in receiving notifications when their Twitch Rust event drops were available.
                                </Typography>
                                <Box sx={{ mt: "13px" }}>
                                    <HoverButton text={"Python"}/>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Box>
                <Box sx={{
                    mb: "2%",
                    mt: "30px",
                    gap: "13%",
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: "center",
                    flexDirection: {xs: "column", sm: "row"}
                }}>
                    <Card hasHover={false} sx={{mb: {xs: "3%", sm: 0}}} image={sitelogo}
                          imageSx={{objectFit: "contain", background: "#10151D"}}>
                        <Box sx={{
                            gap: "10px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "center"
                        }}>
                            <Typography sx={{
                                fontSize: "28px",
                                color: "#252c42",
                                fontWeight: "bold",
                                fontFamily: "MyFont"
                            }}>This website</Typography>
                            <Typography sx={{
                                pr: "10px",
                                pl: "10px",
                                fontSize: "15px",
                                color: "#252c42",
                                fontWeight: "bold",
                                textAlign: "justify",
                                fontFamily: "MyFont"
                            }}>
                                A means to showcase my experiences and progression as a software developer.
                            </Typography>
                            <Box sx={{
                                mt: "20px",
                                gap: "10px",
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                <HoverButton text={"React"}/>
                                <HoverButton text={"JavaScript"}/>
                            </Box>
                        </Box>
                    </Card>
                </Box>
                <ProjectPopUp/>
            </Box>
        </>
    );
};

export default ProjectsSector;