import React from 'react';
import {Box, Typography} from "@mui/material";
import TypewriterComponent from "typewriter-effect";
import img from "../resources/images/my_pic_2.jpg";
import Navbar from "./sub-components/HeroSector/Navbar"
import NavbarLink from "./sub-components/HeroSector/NavbarLink";
import GitHubButton from "./sub-components/HeroSector/GitHubButton";
import LinkedInButton from "./sub-components/HeroSector/LinkedInButton";

const HeroSector = () => {
    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
        }}>
            <Navbar/>
            <Box
                src={img}
                component={'img'}
                alt={'Airton Tavares'}
                sx={{
                    top: "10%",
                    height: {xs: "120px", sm: "150px"},
                    borderRadius: "100px",
                    boxShadow: "0 0 5px 1px white",
                    animation: "animate 2s infinite ease alternate, pulsate 2s infinite",
                    "@keyframes animate": {
                        "to": {
                            "transform": "translateY(10px)"
                        }
                    },
                    "@keyframes pulsate": {
                        "0%": {
                            boxShadow: "0 0 5px 1px white"
                        },
                        "50%": {
                            boxShadow: "0 0 10px 2px white"
                        },
                        "100%": {
                            boxShadow: "0 0 5px 1px white"
                        }
                    }
                }}
            />
            <Box sx={{display: "flex", alignItems: 'center', justifyContent: "center", mt: {xs: "2%", sm: "1%"}}}>
                <Typography
                    sx={{
                        color: "white",
                        fontSize: {xs: "20px", sm: "30px"},
                        fontFamily: "MyFont"
                    }}
                >
                    hey 👋 I'm
                </Typography>
                <Typography
                    sx={{
                        ml: "10px",
                        fontSize: {xs: "20px", sm: "30px"},
                        fontFamily: "MyFont",
                        whiteSpace: "nowrap",
                        backgroundSize: "200%",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        animation: "background-pan 2s linear infinite",
                        backgroundImage: `linear-gradient(90deg, #0c629b, #00B4D8, #FFFFFF, #0c629b, #00B4D8, #FFFFFF, #0c629b)`,
                        "@keyframes background-pan": {
                            '0%': {
                                backgroundPosition: '100% 50%',
                            },
                            '100%': {
                                backgroundPosition: '0% 50%',
                            },
                        }
                    }}
                >
                    Airton Tavares
                </Typography>
            </Box>
            <Box sx={{alignItems: 'center', justifyContent: "center", fontSize: {xs: "15px", sm: "20px"}}}>
                <TypewriterComponent
                    options={
                        {
                            strings: ['Software Developer', 'Doer', 'Dreamer'],
                            autoStart: true,
                            loop: true,
                            delay: 35
                        }
                    }
                />
            </Box>
            <Box sx={{
                mt: "1%",
                display: 'flex',
                fontSize: {xs: "15px", sm: "20px"},
                alignItems: 'center',
                justifyContent: "center",
            }}>
                <Typography sx={{mr: "5px", fontFamily: "MyFont", fontSize: {xs: "15px", sm: "18px"}}}>Currently working with</Typography>
                <NavbarLink middle={false} text={"Azores Airlines"} link={"https://www.azoresairlines.pt"}
                            sx={{pb: "1px", color: "#6DC8E0"}} underColor={"rgba(109,200,224,0.5)"}/>
            </Box>
            <Box sx={{
                mt: "1.5%",
                display: 'flex',
                alignItems: 'center',
                justifyContent: "center",
            }}>
                <GitHubButton hoverColor={"#61749b"} link={"https://github.com/DontLikePeanuts"}/>
                <LinkedInButton hoverColor={"#61749b"} link={"https://www.linkedin.com/in/airtontavares/"}/>
            </Box>
        </Box>
    );
};

export default HeroSector;