import React from 'react';
import {Box, Grid, Typography} from "@mui/material";
import HoverButton from "./sub-components/ProjectsSector/HoverButton";

const SkillsSector = () => {


    return (
        <>
        <Box id={"skills"} sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
        }}>
            <Typography
                sx={{
                    mb: "1%",
                    color: "white",
                    fontSize: {xs: "20px", sm: "30px"},
                    fontFamily: "MyFont"
                }}
            >
                Skills
            </Typography>
            <Box sx={{
                mb: "2%",
            }}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={4}>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column"
                        }}>
                            <Typography
                                sx={{
                                    mt: {xs: "3%", sm: "3%"},
                                    mb: {xs: "1%", md: "3%"},
                                    color: "white",
                                    textAlign: "center",
                                    fontFamily: "MyFont",
                                    fontSize: {xs: "18px", sm: "25px"}
                                }}
                            >
                                Actively Use
                            </Typography>
                            <Box sx={{
                                gap: "10px",
                                display: "flex",
                                flexWrap: "wrap",
                                maxWidth: "300px",
                                justifyContent: "center"
                            }}>
                                <HoverButton invertedColor text={"Java"}/>
                                <HoverButton invertedColor text={"PHP"}/>
                                <HoverButton invertedColor text={"React"}/>
                                <HoverButton invertedColor text={"TypeScript"}/>
                                <HoverButton invertedColor text={"Drupal"}/>
                                <HoverButton invertedColor text={"Docker"}/>
                                <HoverButton invertedColor text={"Kubernetes"}/>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={4}/>
                    <Grid item xs={12} sm={12} md={4}>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column"
                        }}>
                            <Typography
                                sx={{
                                    mt: {xs: "3%", sm: "3%"},
                                    mb: {xs: "1%", md: "3%"},
                                    color: "white",
                                    textAlign: "center",
                                    fontFamily: "MyFont",
                                    fontSize: {xs: "18px", sm: "25px"}
                                }}
                            >
                                Have used
                            </Typography>
                            <Box sx={{
                                gap: "10px",
                                display: "flex",
                                flexWrap: "wrap",
                                maxWidth: "300px",
                                justifyContent: "center"
                            }}>
                                <HoverButton invertedColor text={"Python"}/>
                                <HoverButton invertedColor text={"C#"}/>
                                <HoverButton invertedColor text={"Lua"}/>
                                <HoverButton invertedColor text={"Heroku"}/>
                                <HoverButton invertedColor text={"Firebase"}/>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
</>
)
    ;
};

export default SkillsSector;