import React from 'react';
import {Box} from "@mui/material";

const HoverButton = ({text, invertedColor = false}) => {
    return (
        <Box sx={{
            height: "fit-content",
            backgroundColor: '#43577a',
            border: 'solid transparent',
            borderRadius: '16px',
            borderWidth: '0 0 4px',
            boxSizing: 'border-box',
            color: invertedColor ? '#252c42' : '#FFFFFF',
            display: 'inline-block',
            fontFamily: 'din-round, sans-serif',
            fontSize: '15px',
            fontWeight: '700',
            letterSpacing: '.8px',
            lineHeight: '20px',
            margin: '0',
            outline: 'none',
            overflow: 'visible',
            padding: '7px 10px',
            textAlign: 'center',
            touchAction: 'manipulation',
            transform: 'translateZ(0)',
            transition: 'filter .2s, all 0.3s ease-in-out',
            userSelect: 'none',
            WebkitUserSelect: 'none',
            verticalAlign: 'middle',
            whiteSpace: 'nowrap',
            '&:after': {
                backgroundClip: 'padding-box',
                backgroundColor: invertedColor ? '#fafafa' : '#252c42',
                border: 'solid transparent',
                borderRadius: invertedColor ? '13px' : '16px',
                borderWidth: '0 0 4px',
                bottom: '-4px',
                content: "''",
                left: '0',
                position: 'absolute',
                right: '0',
                top: '0',
                zIndex: '-1',
            },
            '&:hover:not(:disabled)': {
                filter: invertedColor ? 'brightness(0.97)' : 'brightness(1.3)',
                transform: 'translateY(-5px)',
                WebkitFilter: invertedColor ? 'brightness(0.97)' : 'brightness(1.3)',
            },
            '&:disabled': {
                cursor: 'auto',
            },
        }}>
            {text || "button"}
        </Box>
    );
};


export default HoverButton;