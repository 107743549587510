import React, {useEffect, useRef} from 'react';
import * as THREE from 'three';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass.js';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer.js';
import {AfterimagePass} from 'three/examples/jsm/postprocessing/AfterimagePass.js';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import {Box} from "@mui/material";

const Cube = () => {
    const containerRef = useRef(null);

    useEffect(() => {
        let camera, scene, renderer, composer;
        let mesh;
        let afterimagePass = 0.96;
        let controls;
        let container = containerRef.current;

        const init = () => {
            renderer = new THREE.WebGLRenderer({alpha: true});
            renderer.setPixelRatio(1);
            renderer.setSize(100, 50);
            renderer.outputEncoding = THREE.LinearEncoding;
            container.appendChild(renderer.domElement);

            camera = new THREE.PerspectiveCamera(40, 100 / 50, 1, 1000);
            camera.position.z = 400;

            scene = new THREE.Scene();
            scene.background = new THREE.Color(0x10151D);
            scene.fog = new THREE.Fog(0x000000, 1, 1000);

            const geometry = new THREE.BoxGeometry(150, 150, 150, 2, 2, 2);
            const material = new THREE.MeshNormalMaterial();
            mesh = new THREE.Mesh(geometry, material);
            scene.add(mesh);

            // postprocessing
            composer = new EffectComposer(renderer);
            composer.addPass(new RenderPass(scene, camera));

            afterimagePass = new AfterimagePass();
            composer.addPass(afterimagePass);

            controls = new OrbitControls(camera, renderer.domElement);
            controls.enableDamping = true; // Add inertia/momentum to the controls
            controls.dampingFactor = 0.01;
            controls.rotateSpeed = 0.25;

            window.addEventListener('resize', onWindowResize);

            if (typeof TESTING !== 'undefined') {
                for (let i = 0; i < 45; i++) {
                    render();
                }
            }
        };

        const onWindowResize = () => {
            camera.aspect = 100 / 50;
            camera.updateProjectionMatrix();

            renderer.setSize(100, 50);
            composer.setSize(100, 50);
        };

        const render = () => {
            controls.update(); // Update the controls before rendering

            mesh.rotation.x += 0.005;
            mesh.rotation.y += 0.01;

            composer.render();
        };

        const animate = () => {
            requestAnimationFrame(animate);
            render();
        };

        init();
        animate();

        // Clean up the scene when the component unmounts
        return () => {
            while (container.firstChild) {
                container.removeChild(container.firstChild);
            }
        };
    }, []);

    return <Box sx={{zIndex: 1, cursor: "move"}} ref={containerRef}/>;
};

export default Cube;