import React from 'react';
import {Box} from "@mui/material";

const EmailButton = () => {

    const handleEmailClick = () => {
        const email = 'airtontavares_portfolio@outlook.com';
        window.location.href = `mailto:${email}`;
    };

    return (
        <Box onClick={handleEmailClick}
            sx={{
                p: "13px 22px",
                fontSize: "18px",
                color: "#FFFFFF",
                cursor: "pointer",
                borderRadius: "8px",
                border: "2px solid #FFFFFF",
                transition: "0.4s all ease-in-out",
                animation: "pulsate-email 2s infinite ease-in-out",
                '&:hover': {
                    color: "#252c42",
                    border: "2px solid #252c42",
                    backgroundColor: "#FFFFFF"
                },
                "@keyframes pulsate-email": {
                    "0%": {
                        boxShadow: "0 0 10px 2px #FFFFFF"
                    },
                    "50%": {
                        boxShadow: "0 0 15px 3px #FFFFFF"
                    },
                    "100%": {
                        boxShadow: "0 0 10px 2px #FFFFFF"
                    }
                }
            }}>
            Shoot me an 📧
        </Box>
    );
};


export default EmailButton;