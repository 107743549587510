import React from 'react';
import {Box} from "@mui/material";

const NavbarLink = ({text, link, href, sx, underColor, middle = true}) => {
    const handleClick = () => {
        window.open(link, '_blank');
    };

    const handleScrollClick = () => {
        document.getElementById(href).scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <Box
            onClick={(link && handleClick) || (href && handleScrollClick)}
            sx={{
                cursor: "pointer",
                position: 'relative',
                '&:before': {
                    width: '0',
                    left: middle ? '50%' : '0',
                    height: '2px',
                    content: "''",
                    bottom: '-3px',
                    visibility: 'hidden',
                    position: 'absolute',
                    backgroundColor: underColor || 'white',
                    transform: middle ? 'translate(-50%,0%)' : 'scaleX(50%, 0%)',
                    transition: 'all 0.3s ease-in-out',
                },
                '&:hover:before': {
                    width: '100%',
                    visibility: 'visible',
                },
                ...sx
            }}
        >
            {text}
        </Box>
    );
};

export default NavbarLink;