import React from 'react';
import {IconButton} from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const LinkedInButton = ({link, baseColor, hoverColor}) => {
    const handleClick = () => {
        window.open(link, '_blank');
    };

    return (
        <IconButton
            sx={{
                color: baseColor || 'white',
                transition: 'color 0.3s ease',
                '&:hover': {
                    color: hoverColor || 'black',
                    cursor: 'pointer',
                },
                '&:before': {
                    width: '0',
                    left: '50%',
                    height: '2px',
                    content: "''",
                    bottom: '-3px',
                    visibility: 'hidden',
                    position: 'absolute',
                    backgroundColor: hoverColor || 'white',
                    transform: 'translate(-50%,0%)',
                    transition: 'all 0.3s ease-in-out',
                },
                '&:hover:before': {
                    width: '65%',
                    visibility: 'visible',
                },
            }}
            onClick={handleClick}>
            <LinkedInIcon/>
        </IconButton>
    );
};

export default LinkedInButton;