import React from 'react';
import {Box, Typography} from "@mui/material";
import EmailButton from "./sub-components/ContactsSector/EmailButton";

const ContactsSector = () => {

    return (
        <>
            <Box id={"contacts"} sx={{
                mb: "100px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                mt: {xs: "3%", sm: "3%"},
            }}>
                <Typography
                    sx={{
                        mb: "1%",
                        color: "white",
                        fontSize: {xs: "20px", sm: "30px"},
                        fontFamily: "MyFont"
                    }}
                >
                    Contacts
                </Typography>
                <EmailButton/>
            </Box>
        </>
    );
};

export default ContactsSector;