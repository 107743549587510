import React, {useState} from 'react';
import {Close} from "@mui/icons-material";
import {Box, Divider, Typography} from "@mui/material";
import img from "../../../resources/images/logo_name.png";
import {Carousel, useAnimationOffsetEffect } from '@mantine/carousel';

const ProjectPopUp = ({isOpen, setIsOpen, title = "Title", description = "Description", images = [img, img], children}) => {
    const [embla, setEmbla] = useState(null);

    const handleClose = (event) => {
        if (event.target === event.currentTarget) {
            document.body.style.overflow = "";
            setIsOpen(false);
        }
    };

    useAnimationOffsetEffect(embla, 200);

    return (
        <Box onClick={handleClose}
             sx={{
                 top: "50%",
                 left: "50%",
                 transform: "translate(-50%, -50%)",
                 zIndex: 2,
                 width: "100%",
                 height: "100%",
                 display: "flex",
                 position: "fixed",
                 alignItems: "center",
                 opacity: isOpen ? 1 : 0,
                 justifyContent: "center",
                 flexDirection: {xs: "column", md: "row"},
                 backgroundColor: "rgba(0,0,0,0.7)",
                 transition: 'opacity 0.3s ease-in-out',
                 pointerEvents: isOpen ? "auto" : "none",
             }}>
            <Box sx={{
                minWidth: "320px",
                maxWidth: {xs: "320px", sm: "450px", md: "55%"},
                backgroundColor: "white",
            }}>
                <Carousel
                    mx="auto"
                    getEmblaApi={setEmbla}
                    loop
                    withIndicators
                >
                    {images.map((image, i) =>
                        <Carousel.Slide key={i}>
                            <Box
                                src={image}
                                alt={'Image'}
                                component={'img'}
                                sx={{
                                    height: {xs: "300px", sm: "400px", md: "625px"},
                                    objectFit: "scale-down",
                                }}
                            />
                        </Carousel.Slide>)}
                </Carousel>
            </Box>
            <Box sx={{
                width: {xs: "320px", sm: "450px", md: "384px"},
                height: {xs: "350px", sm: "400px", md: "629px"},
                backgroundColor: "white",
            }}>
                <Box sx={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                    <Close onClick={handleClose} sx={{cursor: "pointer", color: "#43577a"}}/>
                </Box>
                <Box sx={{
                    gap: "10px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center"
                }}>
                    <Typography sx={{
                        fontSize: {xs: "25px", md: "35px"},
                        color: "#252c42",
                        fontWeight: "bold",
                        fontFamily: "MyFont"
                    }}>{title}</Typography>
                    <Divider variant={"middle"} sx={{width: "90%"}}/>
                    <Typography sx={{
                        pr: "10px",
                        pl: "10px",
                        fontSize: {xs: "12px", sm: "15px", md: "20px"},
                        color: "#252c42",
                        textAlign: "justify",
                        fontFamily: "MyFont"
                    }}>
                        {description}
                    </Typography>
                    {children}
                </Box>
            </Box>
        </Box>
    );
};


export default ProjectPopUp;