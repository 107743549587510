import React from 'react';
import Cube from "../Cube";
import {Box, Grid} from "@mui/material";
import NavbarLink from "./NavbarLink";

const Navbar = () => {
    return (
        <Grid container>
            <Grid item xs={0.5} sm={1} md={2} lg={3}/>
            <Grid item sx={{pt: "10px", mr: "30px"}} xs={0.5} sm={0.5} md={0.5} lg={0.5}>
                <Cube/>
            </Grid>
            <Grid item xs={8.5} sm={7} md={4.5} lg={4}/>
            <Grid item xs={1} sm={2} md={3} lg={1.5}>
                <Box sx={{
                    pt: "25px",
                    gap: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                }}>
                    <NavbarLink href={"projects"} text={"Projects"}/>
                    <NavbarLink href={"skills"} text={"Skills"}/>
                    <NavbarLink href={"contacts"} text={"Contacts"}/>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Navbar;